<template>
  <div style="text-align:left;">
    <TopNav />
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <ModalNuevoProfesional ref="modalnuevoprofesional" />
      <div class="main-container">       
        <div v-if="loaded" class="articulo-principal">
          <!--<h2 style="margin-bottom:20px;">Profesionales <b-icon icon="person-fill"></b-icon></h2>-->

          <div class="card shadow border-0 mb-7">
            <div style="background:#FFFFFF;" class="card-header">
              <h5 class="mb-0">Profesionales </h5>
            </div>
            <div class="table-responsive">
              <table class="table table-hover table-nowrap">
                <thead style="background-color: rgb(248, 248, 248)" class="">
                  <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Horarios</th>
                    <th scope="col">Calificación</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Contacto</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    style="vertical-align: middle;" 
                    v-for="p in profesionales" 
                    :key="p.profesional.id"
                    :class="{'suspended-row': p.profesional.suspendido == 1}"
                  >
                    <td>
                      <img 
                        style="height:80px; width: 80px;" 
                        :src="(p.profesional.foto && p.profesional.foto != '') ? p.profesional.foto : 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'" 
                        alt="Imagen profesional" 
                        class="avatar avatar-sm rounded-circle me-2"
                      >
                      <router-link 
                        :to="'VerProfesional/'+p.profesional.id" 
                        class="text-heading font-semibold"
                      >
                        {{ p.profesional.nombre + ' ' + p.profesional.apellido }}
                      </router-link>
                    </td>
                    <td style="vertical-align: middle;">
                      <div v-for="h in p.horarios" :key="h.id">
                        <span v-for="i in 7" v-if="h.repeticion[i-1] == 1">
                          {{ dias[i-1] }} 
                        </span> 
                        entre {{ timeConvert(h.inicio) + ' - ' + timeConvert(h.termino) }} 
                        en <span v-for="c in h.comunas" :key="c.id">{{ c.nombre }} </span>
                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <div v-if="p.clasificacion[0] == 0">
                        <span style="font-style: italic;">Sin calificaciones</span>
                      </div>
                      <div v-else>
                        <span v-for="s in Math.trunc(p.clasificacion[1]/p.clasificacion[0])" :key="s">
                          <b-icon class="star" icon="star-fill"></b-icon>
                        </span> 

                        <span v-if="Math.trunc(p.clasificacion[1]/p.clasificacion[0]) != p.clasificacion[1]/p.clasificacion[0]">
                          <b-icon class="star" :icon="(p.clasificacion[1]/p.clasificacion[0] - Math.floor(p.clasificacion[1]/p.clasificacion[0])) >= 0.5 ? 'star-half' : 'star'"></b-icon>
                        </span> 

                        <span v-for="s in Math.trunc(5 - p.clasificacion[1]/p.clasificacion[0])" :key="s">
                          <b-icon class="star" icon="star"></b-icon>
                        </span> 
                        <span>({{ (p.clasificacion[1]/p.clasificacion[0]).toFixed(2) }}, {{ p.clasificacion[0] }} ordenes)</span> 
                      </div>
                    </td>
                    <td style="vertical-align: middle;">
                      <span v-if="p.profesional.atendiendo_orden" class="medalla atendiendo">
                        Atendiendo <b-icon icon="eye"></b-icon>
                      </span>
                      <span v-else class="medalla libre">
                        Libre
                      </span>
                      <!-- Suspended Badge -->
                      <span v-if="p.profesional.suspendido == 1" class="badge badge-suspended">
                        Suspendido
                      </span>
                      <!-- Suspended Badge -->
                      <span v-if="p.profesional.visibilidad_restringida == 1" class="badge badge-restricted">
                        Visibilidad Restringida
                      </span>
                    </td>
                    <td style="vertical-align: middle;">
                      <span class="badge badge-lg badge-dot">
                        <b-icon icon="whatsapp"></b-icon>    
                        <b-icon icon="letter"></b-icon>    
                      </span>
                    </td>
                    <td style="vertical-align: middle;" class="text-end">
                      <button type="button" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <router-link :to="'VerProfesional/'+p.profesional.id" class="">
                          <b-icon icon="gear"></b-icon>  
                        </router-link>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer border-0">
              <span class="text-muted text-sm">
                Mostrando {{ profesionales.length }} profesionales de los {{ profesionales.length }} encontrados
              </span>
              <div style="margin-top:20px;">
                <button @click="showCrearModal()" class="btn btn-std">Crear un nuevo profesional</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="articulo-principal"> cargando... </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js';
import SideNav from '@/components/SideNav.vue';
import TopNav from '@/components/TopNav.vue';
import ModalNuevoProfesional from '@/components/ModalNuevoProfesional.vue';

export default {
  name: 'HelloWorld',
  props: ['id'],
  components: {
    SideNav,
    ModalNuevoProfesional,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      profesionales: [],
      dias: ['Lu', 'Ma', 'Mi', 'Jue', 'Vi', 'Sa', 'Do'],
    };
  },
  created() {
    this.initialize_();
  },
  methods: {
    ...mapActions(['initialize']),
    showCrearModal() {
      this.$refs.modalnuevoprofesional.show();
    },
    timeConvert(n) {
      const num = n;
      const hours = Math.floor(num / 60);
      const minutes = num % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    initialize_() {
      this.loaded = false;
      axios.get(`${window.hostname}api/admin/getprofesionales`)
        .then(response => {
          this.loaded = true;
          this.profesionales = response.data.profesionales;
        })
        .catch(e => {
          this.loaded = true;
          console.log("Error al cargar...", e);
        });
    },
  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'tipos', 'admin_datos']),
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, { keys: ['Nombre'] });
    this.$root.$on('update-profesionales', () => {      
      this.initialize_();
    });
  },
  watch: {
    comunas_buscadas(val) {
      this.resultado_fuse_comuna = this.fComunas.search(val);
    },
  },
};
</script>

<style scoped>
.star{
  cursor: pointer;
  margin-right:4px;
  height:18px;
  width: 18px;
  color:#DAA520;
  /*color:#DAA520;*/
}

.medalla{
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 4px;
  width:100px;
  display:inline-block;
  text-align: center;
}

.atendiendo{
  background-color: #2ECC40;
  color:#FFFFFF;
}

.libre{
  background-color: #0074D9;
  color:#FFFFFF;
}

.badge-suspended {
  display: inline-block;
  background-color: #e74c3c; /* Red color for suspension */
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
}
.badge-restricted {
  display: inline-block;
  background-color: rgb(40, 40, 40); 
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
}

.suspended-row {
  background-color: #f8d7da; /* Light red background */
}

td{
  text-align: left;
}

.articulo-principal{

}

table
{ 
    margin-left: auto;
    margin-right: auto;
}

.column-right{
  background-color: rgb(242, 247, 251);
  min-height:133vh;
  padding-top:80px;
  padding-left:260px;
  width:100%;
}

.main-container{
  background-color: white;
  margin-top:10px;
}

@media (max-width: 800px) {
 .column-right{
  padding-left:0px;
 }
}
</style>
